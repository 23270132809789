'use client';

import { ColorField, Content } from '@prismicio/client';

import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';

import { EmblaCarouselType } from 'embla-carousel';
import Autoplay, { AutoplayType } from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';

import { cn } from '@/lib/utils';

import SliceCta from '@/slices/_shared/cta';
import NewsTag from '@/slices/NewsCarousel/_components/news-tag';

interface DesktopNewsCarouselProps {
  speed: number;
  items: Content.NewsCarouselSliceDefaultItem[];
  components: JSXMapSerializer;
  backgroundColor: ColorField;
}

const DesktopNewsCarousel = ({
  speed,
  items,
  components,
  backgroundColor,
}: DesktopNewsCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      axis: 'y',
      align: 'start',
      loop: true,
      containScroll: 'keepSnaps',
    },
    [
      Autoplay({
        delay: speed,
        stopOnInteraction: false,
        stopOnMouseEnter: false, // handled manually to work on the illustration too
      }),
    ],
  );

  const [allSlideDisplayed, setAllSlideDisplayed] = useState(false);

  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const updateSelectedSlide = useCallback((api: EmblaCarouselType) => {
    setSelectedSlideIndex(api.selectedScrollSnap());
  }, []);

  const selectSlide = useCallback(
    (index: number) => {
      if (!emblaApi) {
        return;
      }

      if (allSlideDisplayed) {
        setSelectedSlideIndex(index);
      } else {
        emblaApi.scrollTo(index);
      }
    },
    [emblaApi, allSlideDisplayed],
  );

  const pauseScroll = useCallback(() => {
    const autoplay: AutoplayType = emblaApi?.plugins()?.autoplay;
    if (!emblaApi || !autoplay) {
      return;
    }

    autoplay.stop();
  }, [emblaApi]);

  const resumeScroll = useCallback(() => {
    const autoplay: AutoplayType = emblaApi?.plugins()?.autoplay;
    if (!emblaApi || !autoplay) {
      return;
    }

    autoplay.play();
  }, [emblaApi]);

  // Synchronize scroll and selected slide (allow us to update the image)
  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    setAllSlideDisplayed(emblaApi.scrollSnapList().length === 1);

    updateSelectedSlide(emblaApi);
    emblaApi.on('select', updateSelectedSlide);
    emblaApi.on('reInit', updateSelectedSlide);
  }, [emblaApi, updateSelectedSlide]);

  const hasLessThanThree = items.length < 3;

  return (
    <div
      className="relative flex w-full flex-row items-center"
      onMouseEnter={pauseScroll}
      onMouseLeave={resumeScroll}
    >
      <div
        className="absolute -bottom-6 -left-6 flex h-1/2 w-[calc(100%+48px)] items-center justify-center self-center rounded-2xl rounded-tr-md p-8 text-white shadow-lg"
        style={{
          backgroundColor: backgroundColor
            ? `${backgroundColor.toString()}`
            : 'hsl(var(--primary))',
        }}
      />
      <div className="z-20 w-1/2 rounded-2xl bg-white p-6 shadow-[0_0_30px_-15px_hsl(var(--muted-foreground))]">
        <div
          className={cn(
            'relative overflow-hidden',
            !allSlideDisplayed
              ? 'after:pointer-events-none after:absolute after:inset-x-0 after:bottom-16 after:h-16 after:bg-gradient-to-t after:from-white'
              : '',
          )}
          ref={emblaRef}
        >
          <div
            className={cn(
              'flex touch-pan-x flex-col gap-y-2 backface-hidden',
              allSlideDisplayed ? 'justify-center' : '',
              hasLessThanThree ? 'h-96' : 'h-112',
            )}
          >
            {items.map(({ title, summary, label_name, label_color }, index) => (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                // Using a drop-shadow to highlight the selected item added 2px
                // to the item size and therefore messed up with Embla calculations.
                // We used an inner-shadow instead to prevent modifying the item size.
                className={cn(
                  'flex flex-col gap-y-2 rounded p-5 text-left hover:shadow-[inset_0_0_1px_1px_hsl(var(--primary))]',
                  index === selectedSlideIndex
                    ? 'bg-primary-50 shadow-[inset_0_0_2px_1px_hsl(var(--primary))]'
                    : '',
                )}
                onClick={() => selectSlide(index)}
                type="button"
              >
                <div className="flex h-40 flex-col justify-around">
                  {label_name ? (
                    <NewsTag color={label_color} label={label_name} />
                  ) : null}
                  {/* Here we don't need to modify the Prismic riche text because we know for a fact we are in desktop */}
                  <PrismicRichText components={components} field={title} />
                  <PrismicRichText components={components} field={summary} />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div
        className={cn(
          'relative w-1/2 rounded-r-2xl bg-cover bg-center shadow-[0_0_30px_-15px_hsl(var(--muted-foreground))] transition-all duration-1000',
          hasLessThanThree ? 'h-96' : 'h-112',
        )}
      >
        <Image
          src={items[selectedSlideIndex].illustration.url ?? ''}
          alt=""
          role="presentation"
          fill
          priority
          objectFit="cover"
          className="absolute size-full rounded-r-2xl"
          // If the image from "images.prismic" domain, it went through imgix and can be optimized.
          unoptimized={
            !items[selectedSlideIndex].illustration.url?.includes(
              'images.prismic',
            )
          }
        />
        <SliceCta
          field={items[selectedSlideIndex].button}
          className="absolute inset-x-4 bottom-4 xl:left-auto"
        />
      </div>
    </div>
  );
};

export default DesktopNewsCarousel;
