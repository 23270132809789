import { PrismicNextLink } from '@prismicio/next';
import { format } from 'date-fns';
import Image from 'next/image';

import { cn } from '@/lib/utils';

import { PressArticleDocumentData } from '../../../../../prismicio-types';

interface PressArticleProps {
  article: PressArticleDocumentData;
  className?: string;
}

const PressArticle = ({ article, className }: PressArticleProps) => {
  return (
    <PrismicNextLink
      field={article.link}
      className={cn(
        'flex translate-y-0 flex-col overflow-hidden rounded-lg shadow-md duration-300 hover:-translate-y-3',
        className,
      )}
    >
      <div className="relative h-full grow">
        {article.cover.url ? (
          <Image
            src={article.cover.url}
            alt=""
            role="presentation"
            className="h-32 w-full object-cover xl:h-36"
            width={200}
            height={144}
            // If the image from "images.prismic" domain, it went through imgix and can be optimized.
            unoptimized={!article.cover.url?.includes('images.prismic')}
          />
        ) : null}
      </div>
      <div className="flex shrink-0 flex-col gap-2 p-6">
        <div className="flex items-center justify-start gap-4">
          {article.logo.url ? (
            <Image
              src={article.logo.url}
              alt=""
              role="presentation"
              className="max-h-10 w-auto"
              width={100}
              height={50}
              // If the image from "images.prismic" domain, it went through imgix and can be optimized.
              unoptimized={!article.logo.url?.includes('images.prismic')}
            />
          ) : null}
          <p className="grow text-sm text-muted-foreground">
            {format(article.published_at?.toString() ?? '', 'dd/MM/yyyy')}
          </p>
        </div>
        <p className="line-clamp-2  text-lg font-semibold">{article.title}</p>
      </div>
    </PrismicNextLink>
  );
};

export default PressArticle;
