'use client';

import { Content, GroupField } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
import { EmblaCarouselType, EmblaEventType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@/app/_components/ui/button';
import { Icons } from '@/app/_components/ui/icons';
import { useBreakpoint } from '@/lib/style/hooks';
import { cn } from '@/lib/utils';

import heading from '@/slices/_shared/heading';

import { Simplify } from '../../../../prismicio-types';

const TWEEN_FACTOR_BASE = 0.12;

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max);

const Carousel = ({
  items,
}: {
  items: GroupField<Simplify<Content.CarouselSliceDefaultPrimaryItemsItem>>;
}) => {
  const [shouldDisplayArrow, setShouldDisplayArrow] = useState(false);
  // make sure carousel does not glich if minimum slides item are not met to loop
  const isDesktop = useBreakpoint('md');
  const isMobile = !isDesktop;
  const tweenFactor = useRef(0);
  const tweenNodes = useRef<HTMLElement[]>([]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      axis: 'x',
      align: 'center',
      loop: true,
      watchResize: true,
    },
    [
      Autoplay({
        delay: 5000,
        stopOnMouseEnter: true,
      }),
    ],
  );

  const setTweenNodes = useCallback((api: EmblaCarouselType): void => {
    tweenNodes.current = api
      .slideNodes()
      .map((node) => node.querySelector('.slide') as HTMLElement);
  }, []);

  const setTweenFactor = useCallback((api: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * api.scrollSnapList().length;
  }, []);

  const tweenScale = useCallback(
    (api: EmblaCarouselType, eventName?: EmblaEventType) => {
      const engine = api.internalEngine();
      const scrollProgress = api.scrollProgress();
      const slidesInView = api.slidesInView();
      const isScrollEvent = eventName === 'scroll';

      api.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress;
        const slidesInSnap = engine.slideRegistry[snapIndex];

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

          if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach((loopItem) => {
              const target = loopItem.target();

              if (slideIndex === loopItem.index && target !== 0) {
                const sign = Math.sign(target);

                if (sign === -1) {
                  diffToTarget = scrollSnap - (1 + scrollProgress);
                }
                if (sign === 1) {
                  diffToTarget = scrollSnap + (1 - scrollProgress);
                }
              }
            });
          }

          const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current);
          const scale = numberWithinRange(tweenValue, 0, 1).toString();
          const tweenNode = tweenNodes.current[slideIndex];
          if (tweenNode !== null) {
            // let transform for loop active :
            if (tweenNode.style.transform.includes('scale')) {
              tweenNode.style.transform = tweenNode.style.transform.replace(
                /scale\(([^)]*)\)/,
                `scale(${scale})`,
              );
            } else {
              tweenNode.style.transform += ` scale(${scale})`;
            }
          }
        });
      });
    },
    [],
  );

  const canScroll = useCallback(
    (innerEmblaApi: EmblaCarouselType) => {
      const tmpShouldDisplayArrow =
        innerEmblaApi.canScrollNext() ?? innerEmblaApi.canScrollPrev();
      if (
        tmpShouldDisplayArrow !== undefined &&
        shouldDisplayArrow !== tmpShouldDisplayArrow
      ) {
        setShouldDisplayArrow(tmpShouldDisplayArrow);
      }
    },
    [shouldDisplayArrow],
  );

  useEffect(() => {
    if (emblaApi) {
      setTweenNodes(emblaApi);
      setTweenFactor(emblaApi);
      tweenScale(emblaApi);
      emblaApi
        .on('init', canScroll)
        .on('reInit', canScroll)
        .on('reInit', setTweenNodes)
        .on('reInit', setTweenFactor)
        .on('reInit', tweenScale)
        .on('scroll', tweenScale);
      emblaApi.reInit(); // weird fix to display items correctly (not the case on init)
    }
    return () => {
      if (emblaApi) {
        emblaApi
          .off('init', canScroll)
          .off('reInit', canScroll)
          .off('reInit', setTweenNodes)
          .off('reInit', setTweenFactor)
          .off('reInit', tweenScale)
          .off('scroll', tweenScale);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, canScroll, tweenScale]);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <section
      className={cn('relative flex w-full max-w-full justify-center', {
        'w-112': isMobile,
      })}
    >
      <div
        className={cn('flex w-full max-w-full justify-center', {
          'w-80': isMobile,
        })}
      >
        <div className="w-min overflow-hidden p-6" ref={emblaRef}>
          <div
            className={cn('flex touch-pan-y backface-hidden *:mx-5', {
              'justify-center': !shouldDisplayArrow,
            })}
          >
            {items.map((item, index) => (
              <div
                onClick={() =>
                  // @ts-expect-error je sais pas
                  emblaApi && emblaApi.scrollTo(items.indexOf(item))
                }
                role="listitem"
                aria-hidden="true"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="relative h-128 w-64 shrink-0"
              >
                <a
                  // Need it to select it from the effect
                  // eslint-disable-next-line tailwindcss/no-custom-classname
                  className={cn(
                    'slide relative flex size-full scale-100 flex-col justify-end overflow-hidden rounded-lg border-white duration-100 will-change-transform',
                  )}
                  href={item.link ? `${item.link}` : undefined}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={cn(
                      'absolute z-0 size-full scale-100 duration-300 will-change-transform backface-hidden hover:scale-125',
                      'after:absolute after:bottom-0 after:z-[1] after:h-1/2 after:w-full after:bg-gradient-to-b after:from-transparent after:to-slate-900', // after
                    )}
                  >
                    <Image
                      src={item.image.url ?? ''}
                      alt=""
                      role="presentation"
                      fill
                      priority
                      className="absolute size-full object-cover"
                      // If the image from "images.prismic" domain, it went through imgix and can be optimized.
                      unoptimized={!item.image.url?.includes('images.prismic')}
                    />
                  </div>
                  <div className="relative z-10 flex w-full flex-col items-center px-1 py-6">
                    <PrismicRichText
                      field={item.title}
                      components={{
                        ...heading({ theme: 'white', fontWeight: 'bold' }),
                        // eslint-disable-next-line react/no-unstable-nested-components
                        paragraph: ({ children }) => (
                          <p className="pt-2 text-center text-white">
                            {children}
                          </p>
                        ),
                      }}
                    />
                    <PrismicRichText
                      field={item.subtitle}
                      components={{
                        ...heading({ theme: 'white', fontWeight: 'bold' }),
                        // eslint-disable-next-line react/no-unstable-nested-components
                        paragraph: ({ children }) => (
                          <p className="pb-2 text-center text-white">
                            {children}
                          </p>
                        ),
                      }}
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {shouldDisplayArrow ? (
        <>
          <div
            className={cn(
              'pointer-events-none absolute -inset-y-12 left-0 z-10 flex items-center bg-gradient-to-r from-white',
              'w-16 sm:w-32 md:w-48',
            )}
          >
            <Button
              onClick={onPrevButtonClick}
              className="pointer-events-auto ml-2 size-8 rounded-full px-1 sm:ml-4 sm:size-12 sm:px-2 md:ml-8"
            >
              <Icons.StraightLeftArrow className="size-8 sm:size-12" />
            </Button>
          </div>
          <div
            className={cn(
              'pointer-events-none absolute -inset-y-12 right-0 z-10 flex items-center justify-end bg-gradient-to-l from-white',
              'w-16 sm:w-32 md:w-48',
            )}
          >
            <Button
              onClick={onNextButtonClick}
              cornerStyle="cornerRight"
              className="pointer-events-auto mr-2 size-8 rounded-full px-1 sm:mr-4 sm:size-12 sm:px-2 md:mr-8"
            >
              <Icons.RightArrow className="size-8 sm:size-12" />
            </Button>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Carousel;
